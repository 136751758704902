function buildQueryParams(params: URLSearchParams | Map<string, string>, excludingKeys: string[]) {
  const queryParams: string[] = []
  params.forEach((value, key) => {
    if (!excludingKeys.includes(key)) {
      queryParams.push(`${key}=${value}`)
    }
  })
  return queryParams
}

function getUrl() {
  const url = new URL(window.location.href)
  const params = url.searchParams
  const request = params.has('request') ? params.get('request') : undefined
  if (request) {
    const baseUrl = `${process.env.BASE_URL}/web/answer-request`
    const query = [`token=${request}`, ...buildQueryParams(params, ['request'])]
    return `${baseUrl}?${query.join('&')}`
  } else {
    const baseUrl = `${process.env.BASE_URL}/web/distribution`
    const token = params.has('token') ? params.get('token') : ''
    const requestCache = document.cookie
      .split('; ')
      .map(cookie => cookie.split('='))
      .filter(pair => pair.length == 2 && pair[0] == `ATOCOS-DC-${token}`)
      .map(pair => pair[1])
    const query = [...(requestCache.length > 0 ? [`request=${requestCache[0]}`] : []), ...buildQueryParams(params, [])]
    return `${baseUrl}?${query.join('&')}`
  }
}

function setFillHeightForOldBrowser() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('message', (e: MessageEvent) => {
  try {
    const message = JSON.parse(e.data)
    if ('type' in message && message.type == 'ATOCOS_EVENT') {
      if ('event' in message && message.event == 'ANSWER_START') {
        console.info(message.event)
        if ('distribution' in message && 'request' in message) {
          document.cookie = `ATOCOS-DC-${message.distribution}=${message.request}; max-age=15552000`
        }
      }
      if ('event' in message && message.event == 'ANSWER_END') {
        console.info(message.event)
      }
    }
  } catch (e) {}
})

window.onload = () => {
  const element = document.getElementById('wrapper')
  const iframe = document.createElement('iframe')
  iframe.className = 'content'
  iframe.src = getUrl()
  if (element) {
    element.append(iframe)
  }
  window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
    event.preventDefault()
    event.returnValue = ''
  })
  if (!CSS.supports('height', '1dvh')) {
    window.addEventListener('resize', setFillHeightForOldBrowser)
    setFillHeightForOldBrowser()
  }
}

export {}
